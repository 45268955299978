
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'
import * as FactCheckServices from '@/services/FactCheckService';
import { INTERACTION_TYPE } from "@/types";

@Component
export default class InteractionTypesperHour extends Vue {
  @Prop({ required: true }) from!: Date;
  @Prop({ required: true }) to!: Date;

  labels = {
    '0': "Klik op factcheck artikel",
    '1': "Doorverwijzing naar partner",
    '2': "Gedeeld via facebook",
    '4': "Artikel URL Gekopieerd",
    '5': "Gedeeld via X (twitter)",
    '6': "Gedeeld via whatsapp",
  } as Record<string, string>;

  @Watch('from', { immediate: true })
  @Watch('to')
  async fetchInteractions() {
    const data = await StatServices.CountTypesOfInteractionPerHour(this.from, this.to);
    const result = [] as any[];
    const interactionTypes = Object.keys(INTERACTION_TYPE).filter(a => [INTERACTION_TYPE.TWITTER_CLICK_TROUGH, INTERACTION_TYPE.SOCIAL_CLICK]).filter(x => isNaN(x as unknown as number)).map(e => {
      return e.replaceAll('_', ' ').replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); })
    })

    interactionTypes.splice(7)

    Object.keys(this.labels).forEach((key) => {

      const arr = [] as any[];
      let i = 0
      for (let hour in data) {
        arr.push({
          x: i.toString(),
          y: data[hour][key]
        })
        i++;
      }
      result.push({
        name: this.labels[key],
        data: arr
      })
    })
    
    this.interactionTypesPerHour = result.reverse();
  }



  interactionTypesPerHour = [] as any[];

  chartOptions = {
    chart: {
      height: 350,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#008FFB"],
  }
}

